
@font-face {
    font-family: 'josefin_slab';
    src: url('../fonts/josefinslab-regular-webfont.woff2') format('woff2'),
            url('../fonts/josefinslab-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'josefin_slab';
    src: url('../fonts/josefinslab-bold-webfont.woff2') format('woff2'),
            url('../fonts/josefinslab-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'josefin_slab';
    src: url('../fonts/josefinslab-semibold-webfont.woff2') format('woff2'),
            url('../fonts/josefinslab-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'josefin_slab';
    src: url('../fonts/josefinslab-light-webfont.woff2') format('woff2'),
            url('../fonts/josefinslab-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'josefin_slab';
    src: url('../fonts/josefinslab-thin-webfont.woff2') format('woff2'),
            url('../fonts/josefinslab-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
